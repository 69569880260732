<template>
  <el-container>
      <el-main style="margin-top: 20px">
        <div style="text-align: center;margin:0 auto;">
          <span v-if="showContent == '0'">哈哈，啥也没有，你真是个小可爱！！！</span>
          <span v-if="showContent == '1'">
              <img src="../../assets/jnh/jnh_title.png" width="100%">
              <img style="border-radius: 10px" src="http://154.197.99.71:8082/file/jnh/jay_ticket_back.png" width="100%">
             <div class="scroll-text">
                <div :style="textStyle" class="text gradient-text" style="font-size: 20px">永远爱薇薇 你就是我的可爱女人</div>
              </div>
              <div style="width: 100%;margin:0 auto;margin-top: 20px">
                <swiper :options="swiperOptions" style="border-radius: 10px">
                  <swiper-slide style="height: 500px;text-align: center;width: 100%;" v-for="image in images" :key="image">
                      <div style="position: relative;height: 100%">
                        <img :src="image" height="100%" width="100%" style=""/>
                      </div>
                  </swiper-slide>
                </swiper>
              </div>
          </span>
        </div>
      </el-main>
  </el-container>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      textStyle: {
        position: 'relative',
        whiteSpace: 'nowrap',
        animation: 'scrollText 5s linear infinite'
      },
      showContent:2,
      images: [
        "http://154.197.99.71:8082/file/jnh/01.jpg",
        "http://154.197.99.71:8082/file/jnh/02.jpg",
        "http://154.197.99.71:8082/file/jnh/03.jpg",
        "http://154.197.99.71:8082/file/jnh/04.jpg",
        "http://154.197.99.71:8082/file/jnh/05.jpg",
        "http://154.197.99.71:8082/file/jnh/06.jpg",
        "http://154.197.99.71:8082/file/jnh/07.jpg",
        "http://154.197.99.71:8082/file/jnh/08.jpg",
        "http://154.197.99.71:8082/file/jnh/09.jpg",
        "http://154.197.99.71:8082/file/jnh/10.jpg",
        "http://154.197.99.71:8082/file/jnh/11.jpg",
        "http://154.197.99.71:8082/file/jnh/12.jpg"
      ],
      swiperOptions: {
      autoplay: {
        delay: 4000,
          disableOnInteraction: false
      },
      loop: true,
    },
    };
  },
  // 卸载
  beforeDestroy() {
  },
  beforeCreate(){
  },
  created() {
        this.queryEnablePage();
    },
  mounted() {
  },
  methods: {
    queryEnablePage(){
      this.$axios.get(this.$gameApiurls.queryEnablePage)
        .then((res) => {
          this.showContent = res.data;
        });
    }
  },
};
</script>

<style scope>
body {
  height: 100vh;
  background-color: #f4fafe;
}
p {
  word-break:keep-all;
  white-space: nowrap;
  font-size: 0.28rem;
}

.getWidth {
  word-break:keep-all;
  white-space:nowrap;
  position: absolute;
  opacity: 0;
  top: 0;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

@keyframes scrollText {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.scroll-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  display: inline-block;
}

.gradient-text {
  font-size: 60px;
  font-weight: bold;
  background: -webkit-linear-gradient(45deg, green, red); /* Chrome, Safari */
  background: linear-gradient(45deg, dodgerblue, firebrick); /* 标准语法 */
  -webkit-background-clip: text; /* Chrome, Safari */
  background-clip: text;
  -webkit-text-fill-color: transparent; /* Chrome, Safari */
  color: transparent; /* 兼容不支持background-clip的浏览器 */
}

</style>
